import React, { useEffect, useState } from "react";
import phone from "../../../../assets/images/hero/phone 1.png";
import phone2 from "../../../../assets/images/hero/phone 2.png";

import "./HeroArea.css";
import { useTranslation } from "react-i18next";

const HeroArea = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const [showOptions, setShowOptions] = useState(false);
  const [isG, setIsG] = useState(true);
  const [scrollStopped, setScrollStopped] = useState(false);
  const isDesktop = window.innerWidth > 991; // يمكن تعديل هذه القيمة حسب ما تراه مناسبًا

  // Determine if the user is on a mobile device
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  // Determine if the user is on iOS
  const isIOSDevice =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  // Determine if the user is on Android
  const isAndroidDevice = /Android/i.test(navigator.userAgent);

  // Determine if the user is on MacOS
  const isMacOsDevice = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  // Determine if the user is on Windows
  const isWindowsDevice = /Win64|Win32|Windows/i.test(navigator.platform);
  let mainDownloadLink = "";

  const handleShowAllButtonClick = () => {
    setShowOptions(true);
  };
  useEffect(() => {
    if (!isWindowsDevice && !isIOSDevice && !isMacOsDevice) {
      setIsG(false); // Set `isG` state to false
    }
  }, []);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    // Reset scrollStopped to false when scrolling
    setScrollStopped(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollStopped) return;

    const timeout = setTimeout(() => {
      setScrollStopped(true);
    }, 1000); // توقف حركة الصور بعد مرور 1000 مللي ثانية (1 ثانية)

    return () => clearTimeout(timeout);
  }, [scrollPosition, scrollStopped]);

  return (
    <>
      <section
        id="home"
        className="hero-area"
        style={isArabic ? { direction: "rtl" } : {}}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={
                    isArabic ? { direction: "rtl", textAlign: "right" } : {}
                  }
                >
                  {t("The most modern payment method in Algeria")}
                </h1>
                <p
                  className="wow fadeInLeft"
                  data-wow-delay=".6s"
                  style={
                    isArabic ? { direction: "rtl", textAlign: "right" } : {}
                  }
                >
                  {t("Enjoy secure transactions, low fees, and full control over your money. Pay with a QR code, send and receive money to and from banks and Algeria Post. Join and discover more in the world of digital payments!")}
                </p>
                <div
                  className="button wow fadeInLeft"
                  data-wow-delay=".8s"
                  style={
                    isArabic ? { direction: "rtl", textAlign: "right" } : {}
                  }
                >
                  {isMobileDevice && isIOSDevice && (
                    <a
                      className="btn "
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      style={{ marginBottom: "15px" }}
                    >
                      <i className="lni lni-apple"></i> {t("App Store")}
                    </a>
                  )}
                  {isMacOsDevice && (
                    <a
                      className="btn "
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      style={{ marginBottom: "15px" }}
                    >
                      <i className="lni lni-laptop"></i> {t("Mac Book")}
                    </a>
                  )}
                  {isWindowsDevice && (
                    <a
                      href="https://drive.google.com/file/d/1MyxVnkxHAPTmLKSPiGJkNu90lDmh57Bj/view"
                      className="btn "
                      style={{ marginBottom: "15px" }}
                    >
                      <i className="lni lni-windows"></i> {t("windows")}
                    </a>
                  )}
                  {!isWindowsDevice && !isIOSDevice && !isMacOsDevice && (
                    <a
                      href="https://play.google.com/store/apps/details?id=io.sofizpay&pli=1"
                      className="btn "
                      style={{ marginBottom: "15px" }}
                    >
                      <i className="lni lni-play-store"></i> {t("Google Play")}
                    </a>
                  )}

                  {!showOptions && (
                    <p
                      className={`select ${
                        isArabic ? "select-left" : "select-right"
                      }`}
                      onClick={handleShowAllButtonClick}
                    >
                      {t("Show All")}
                    </p>
                  )}

                  {showOptions && (
                    <>
                      {!isIOSDevice && (
                        <a
                          className="btn btn-w"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          style={{ marginBottom: "15px" }}
                        >
                          <i className="lni lni-apple"></i> {t("App Store")}
                        </a>
                      )}
                      {isG && (
                        <a
                          href="https://play.google.com/store/apps/details?id=io.sofizpay&pli=1"
                          className="btn btn-w"
                          style={{ marginBottom: "15px" }}
                        >
                          <i className="lni lni-play-store"></i>{" "}
                          {t("Google Play")}
                        </a>
                      )}
                      {!isMacOsDevice && (
                        <a
                          className="btn btn-w"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          style={{ marginBottom: "15px" }}
                        >
                          <i className="lni lni-laptop"></i> {t("Mac Book")}
                        </a>
                      )}
                      {!isWindowsDevice && (
                        <a
                          className="btn btn-w"
                          href="https://drive.google.com/file/d/1MyxVnkxHAPTmLKSPiGJkNu90lDmh57Bj/view"
                          style={{ marginBottom: "15px" }}
                        >
                          <i className="lni lni-windows"></i> {t("windows")}
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-lg-7 col-md-12 col-12"
              style={isArabic ? { direction: "rtl", textAlign: "right" } : {}}
            >
              <div className="hero-image" style={{ position: "relative" }}>
                <img
                  className="phone1"
                  src={phone}
                  style={{
                    position: "absolute",
                    zIndex: "2",
                    transform: `translateX(-${
                      isDesktop
                        ? Math.min(scrollPosition * 0.6, 60)
                        : Math.min(scrollPosition * 0.2, 60)
                    }px)`,
                  }}
                  alt="#"
                />
                <img
                  className="phone2"
                  src={phone2}
                  style={{
                    position: "absolute",
                    transform: `translateX(${
                      isDesktop
                        ? Math.min(scrollPosition * 0.6, 60)
                        : Math.min(scrollPosition * 0.2, 60)
                    }px)`,
                  }}
                  alt="#"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal"
        id="staticBackdrop"
        tabIndex="0"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ backdropFilter: "blur(4px)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "30px",
                    color: "#0d6efd",
                    marginBottom: "60px",
                  }}
                >
                  info
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#0d6efd",
                    position: "absolute",
                    top: "60px",
                    textAlign: "center",
                  }}
                >
                  {t("This version will be available soon")}
                </span>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center align-items-center">
              <button
                className="btn"
                id="btn-c"
                style={{
                  background:
                    "radial-gradient(at left top, #0e6cff, #0c2a96), radial-gradient(at right top, #0c2a96, #0c2a9600)",
                  color: "white",
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("Continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroArea;
